import * as React from 'react';
import Cell from './Cell';
function renderCells(items, { colon, prefixCls, bordered }, { component, type, showLabel, showContent }) {
    return items.map(({ props: { label, children, prefixCls: itemPrefixCls = prefixCls, className, style, span = 1, }, key, }, index) => {
        if (typeof component === 'string') {
            return (<Cell key={`${type}-${key || index}`} className={className} style={style} span={span} colon={colon} component={component} itemPrefixCls={itemPrefixCls} bordered={bordered} label={showLabel ? label : null} content={showContent ? children : null}/>);
        }
        return [
            <Cell key={`label-${key || index}`} className={className} style={style} span={1} colon={colon} component={component[0]} itemPrefixCls={itemPrefixCls} bordered={bordered} label={label}/>,
            <Cell key={`content-${key || index}`} className={className} style={style} span={span * 2 - 1} component={component[1]} itemPrefixCls={itemPrefixCls} bordered={bordered} content={children}/>,
        ];
    });
}
const Row = props => {
    const { prefixCls, vertical, row, index, bordered } = props;
    if (vertical) {
        return (<>
        <tr key={`label-${index}`} className={`${prefixCls}-row`}>
          {renderCells(row, props, { component: 'th', type: 'label', showLabel: true })}
        </tr>
        <tr key={`content-${index}`} className={`${prefixCls}-row`}>
          {renderCells(row, props, {
            component: 'td',
            type: 'content',
            showContent: true,
        })}
        </tr>
      </>);
    }
    return (<tr key={index} className={`${prefixCls}-row`}>
      {renderCells(row, props, {
        component: bordered ? ['th', 'td'] : 'td',
        type: 'item',
        showLabel: true,
        showContent: true,
    })}
    </tr>);
};
export default Row;
