import * as React from 'react';
import classNames from 'classnames';
import { convertChildrenToColumns } from 'rc-table/lib/hooks/useColumns';
import CaretDownOutlined from '@ant-design/icons/CaretDownOutlined';
import CaretUpOutlined from '@ant-design/icons/CaretUpOutlined';
import { getColumnKey, getColumnPos, renderColumnTitle } from '../util';
function getMultiplePriority(column) {
    if (typeof column.sorter === 'object' && typeof column.sorter.multiple === 'number') {
        return column.sorter.multiple;
    }
    return false;
}
function getSortFunction(sorter) {
    if (typeof sorter === 'function') {
        return sorter;
    }
    if (sorter && typeof sorter === 'object' && sorter.compare) {
        return sorter.compare;
    }
    return false;
}
function nextSortDirection(sortDirections, current) {
    if (!current) {
        return sortDirections[0];
    }
    return sortDirections[sortDirections.indexOf(current) + 1];
}
function collectSortStates(columns, init, pos) {
    let sortStates = [];
    (columns || []).forEach((column, index) => {
        const columnPos = getColumnPos(index, pos);
        if ('children' in column) {
            sortStates = [...sortStates, ...collectSortStates(column.children, init, columnPos)];
        }
        else if (column.sorter) {
            if ('sortOrder' in column) {
                // Controlled
                sortStates.push({
                    column,
                    key: getColumnKey(column, columnPos),
                    multiplePriority: getMultiplePriority(column),
                    sortOrder: column.sortOrder,
                });
            }
            else if (init && column.defaultSortOrder) {
                // Default sorter
                sortStates.push({
                    column,
                    key: getColumnKey(column, columnPos),
                    multiplePriority: getMultiplePriority(column),
                    sortOrder: column.defaultSortOrder,
                });
            }
        }
    });
    return sortStates;
}
function injectSorter(prefixCls, columns, sorterSates, triggerSorter, defaultSortDirections, pos) {
    return (columns || []).map((column, index) => {
        const columnPos = getColumnPos(index, pos);
        let newColumn = column;
        if (newColumn.sorter) {
            const sortDirections = newColumn.sortDirections || defaultSortDirections;
            const columnKey = getColumnKey(newColumn, columnPos);
            const sorterState = sorterSates.find(({ key }) => key === columnKey);
            const sorterOrder = sorterState ? sorterState.sortOrder : null;
            const upNode = sortDirections.includes('ascend') && (<CaretUpOutlined className={classNames(`${prefixCls}-column-sorter-up`, {
                active: sorterOrder === 'ascend',
            })}/>);
            const downNode = sortDirections.includes('descend') && (<CaretDownOutlined className={classNames(`${prefixCls}-column-sorter-down`, {
                active: sorterOrder === 'descend',
            })}/>);
            newColumn = Object.assign(Object.assign({}, newColumn), { className: classNames(newColumn.className, { [`${prefixCls}-column-sort`]: sorterOrder }), title: (renderProps) => (<div className={`${prefixCls}-column-sorters`}>
            <span>{renderColumnTitle(column.title, renderProps)}</span>
            <span className={classNames(`${prefixCls}-column-sorter`, {
                    [`${prefixCls}-column-sorter-full`]: upNode && downNode,
                })}>
              <span className={`${prefixCls}-column-sorter-inner`}>
                {upNode}
                {downNode}
              </span>
            </span>
          </div>), onHeaderCell: col => {
                    const cell = (column.onHeaderCell && column.onHeaderCell(col)) || {};
                    const originOnClick = cell.onClick;
                    cell.onClick = (event) => {
                        triggerSorter({
                            column,
                            key: columnKey,
                            sortOrder: nextSortDirection(sortDirections, sorterOrder),
                            multiplePriority: getMultiplePriority(column),
                        });
                        if (originOnClick) {
                            originOnClick(event);
                        }
                    };
                    cell.className = classNames(cell.className, `${prefixCls}-column-has-sorters`);
                    return cell;
                } });
        }
        if ('children' in newColumn) {
            newColumn = Object.assign(Object.assign({}, newColumn), { children: injectSorter(prefixCls, newColumn.children, sorterSates, triggerSorter, defaultSortDirections, columnPos) });
        }
        return newColumn;
    });
}
function stateToInfo(sorterStates) {
    const { column, sortOrder } = sorterStates;
    return { column, order: sortOrder, field: column.dataIndex, columnKey: column.key };
}
function generateSorterInfo(sorterStates) {
    const list = sorterStates.filter(({ sortOrder }) => sortOrder).map(stateToInfo);
    // =========== Legacy compatible support ===========
    // https://github.com/ant-design/ant-design/pull/19226
    if (list.length === 0 && sorterStates.length) {
        return Object.assign(Object.assign({}, stateToInfo(sorterStates[0])), { column: undefined });
    }
    if (list.length <= 1) {
        return list[0] || {};
    }
    return list;
}
export function getSortData(data, sortStates, childrenColumnName) {
    const innerSorterStates = sortStates
        .slice()
        .sort((a, b) => b.multiplePriority - a.multiplePriority);
    const cloneData = data.slice();
    const runningSorters = innerSorterStates.filter(({ column: { sorter }, sortOrder }) => {
        return getSortFunction(sorter) && sortOrder;
    });
    // Skip if no sorter needed
    if (!runningSorters.length) {
        return cloneData;
    }
    return cloneData
        .sort((record1, record2) => {
        for (let i = 0; i < runningSorters.length; i += 1) {
            const sorterState = runningSorters[i];
            const { column: { sorter }, sortOrder, } = sorterState;
            const compareFn = getSortFunction(sorter);
            if (compareFn && sortOrder) {
                const compareResult = compareFn(record1, record2, sortOrder);
                if (compareResult !== 0) {
                    return sortOrder === 'ascend' ? compareResult : -compareResult;
                }
            }
        }
        return 0;
    })
        .map(record => {
        const subRecords = record[childrenColumnName];
        if (subRecords) {
            return Object.assign(Object.assign({}, record), { [childrenColumnName]: getSortData(subRecords, sortStates, childrenColumnName) });
        }
        return record;
    });
}
export default function useFilterSorter({ prefixCls, columns, children, onSorterChange, sortDirections, }) {
    const mergedColumns = React.useMemo(() => {
        return columns || convertChildrenToColumns(children);
    }, [children, columns]);
    const [sortStates, setSortStates] = React.useState(collectSortStates(mergedColumns, true));
    const mergedSorterStates = React.useMemo(() => {
        let validate = true;
        const collectedStates = collectSortStates(mergedColumns, false);
        // Return if not controlled
        if (!collectedStates.length) {
            return sortStates;
        }
        const validateStates = [];
        function patchStates(state) {
            if (validate) {
                validateStates.push(state);
            }
            else {
                validateStates.push(Object.assign(Object.assign({}, state), { sortOrder: null }));
            }
        }
        let multipleMode = null;
        collectedStates.forEach(state => {
            if (multipleMode === null) {
                patchStates(state);
                if (state.sortOrder) {
                    if (state.multiplePriority === false) {
                        validate = false;
                    }
                    else {
                        multipleMode = true;
                    }
                }
            }
            else if (multipleMode && state.multiplePriority !== false) {
                patchStates(state);
            }
            else {
                validate = false;
                patchStates(state);
            }
        });
        return validateStates;
    }, [mergedColumns, sortStates]);
    // Get render columns title required props
    const columnTitleSorterProps = React.useMemo(() => {
        const sortColumns = mergedSorterStates.map(({ column, sortOrder }) => ({
            column,
            order: sortOrder,
        }));
        return {
            sortColumns,
            // Legacy
            sortColumn: sortColumns[0] && sortColumns[0].column,
            sortOrder: sortColumns[0] && sortColumns[0].order,
        };
    }, [mergedSorterStates]);
    function triggerSorter(sortState) {
        let newSorterStates;
        if (sortState.multiplePriority === false ||
            !mergedSorterStates.length ||
            mergedSorterStates[0].multiplePriority === false) {
            newSorterStates = [sortState];
        }
        else {
            newSorterStates = [
                ...mergedSorterStates.filter(({ key }) => key !== sortState.key),
                sortState,
            ];
        }
        setSortStates(newSorterStates);
        onSorterChange(generateSorterInfo(newSorterStates), newSorterStates);
    }
    const transformColumns = (innerColumns) => injectSorter(prefixCls, innerColumns, mergedSorterStates, triggerSorter, sortDirections);
    const getSorters = () => {
        return generateSorterInfo(mergedSorterStates);
    };
    return [transformColumns, mergedSorterStates, columnTitleSorterProps, getSorters];
}
