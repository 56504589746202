var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import RcInputNumber from 'rc-input-number';
import UpOutlined from '@ant-design/icons/UpOutlined';
import DownOutlined from '@ant-design/icons/DownOutlined';
import { ConfigConsumer } from '../config-provider';
import SizeContext from '../config-provider/SizeContext';
export default class InputNumber extends React.Component {
    constructor() {
        super(...arguments);
        this.saveInputNumber = (inputNumberRef) => {
            this.inputNumberRef = inputNumberRef;
        };
        this.renderInputNumber = ({ getPrefixCls }) => {
            const _a = this.props, { className, size: customizeSize, prefixCls: customizePrefixCls } = _a, others = __rest(_a, ["className", "size", "prefixCls"]);
            const prefixCls = getPrefixCls('input-number', customizePrefixCls);
            const upIcon = <UpOutlined className={`${prefixCls}-handler-up-inner`}/>;
            const downIcon = <DownOutlined className={`${prefixCls}-handler-down-inner`}/>;
            return (<SizeContext.Consumer>
        {size => {
                const mergeSize = customizeSize || size;
                const inputNumberClass = classNames({
                    [`${prefixCls}-lg`]: mergeSize === 'large',
                    [`${prefixCls}-sm`]: mergeSize === 'small',
                }, className);
                return (<RcInputNumber ref={this.saveInputNumber} className={inputNumberClass} upHandler={upIcon} downHandler={downIcon} prefixCls={prefixCls} {...others}/>);
            }}
      </SizeContext.Consumer>);
        };
    }
    focus() {
        this.inputNumberRef.focus();
    }
    blur() {
        this.inputNumberRef.blur();
    }
    render() {
        return <ConfigConsumer>{this.renderInputNumber}</ConfigConsumer>;
    }
}
InputNumber.defaultProps = {
    step: 1,
};
