var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import RcPagination from 'rc-pagination';
import enUS from 'rc-pagination/lib/locale/en_US';
import classNames from 'classnames';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import RightOutlined from '@ant-design/icons/RightOutlined';
import DoubleLeftOutlined from '@ant-design/icons/DoubleLeftOutlined';
import DoubleRightOutlined from '@ant-design/icons/DoubleRightOutlined';
import MiniSelect from './MiniSelect';
import Select from '../select';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import { ConfigConsumer } from '../config-provider';
export default class Pagination extends React.Component {
    constructor() {
        super(...arguments);
        this.getIconsProps = (prefixCls, direction) => {
            let prevIcon = (<a className={`${prefixCls}-item-link`}>
        <LeftOutlined />
      </a>);
            let nextIcon = (<a className={`${prefixCls}-item-link`}>
        <RightOutlined />
      </a>);
            let jumpPrevIcon = (<a className={`${prefixCls}-item-link`}>
        
        <div className={`${prefixCls}-item-container`}>
          <DoubleLeftOutlined className={`${prefixCls}-item-link-icon`}/>
          <span className={`${prefixCls}-item-ellipsis`}>•••</span>
        </div>
      </a>);
            let jumpNextIcon = (<a className={`${prefixCls}-item-link`}>
        
        <div className={`${prefixCls}-item-container`}>
          <DoubleRightOutlined className={`${prefixCls}-item-link-icon`}/>
          <span className={`${prefixCls}-item-ellipsis`}>•••</span>
        </div>
      </a>);
            // change arrows direction in right-to-left direction
            if (direction === 'rtl') {
                let temp;
                temp = prevIcon;
                prevIcon = nextIcon;
                nextIcon = temp;
                temp = jumpPrevIcon;
                jumpPrevIcon = jumpNextIcon;
                jumpNextIcon = temp;
            }
            return {
                prevIcon,
                nextIcon,
                jumpPrevIcon,
                jumpNextIcon,
            };
        };
        this.renderPagination = (contextLocale) => {
            const _a = this.props, { prefixCls: customizePrefixCls, selectPrefixCls: customizeSelectPrefixCls, className, size, locale: customLocale } = _a, restProps = __rest(_a, ["prefixCls", "selectPrefixCls", "className", "size", "locale"]);
            const locale = Object.assign(Object.assign({}, contextLocale), customLocale);
            const isSmall = size === 'small';
            return (<ConfigConsumer>
        {({ getPrefixCls, direction }) => {
                const prefixCls = getPrefixCls('pagination', customizePrefixCls);
                const selectPrefixCls = getPrefixCls('select', customizeSelectPrefixCls);
                const extendedClassName = classNames(className, {
                    mini: isSmall,
                    [`${prefixCls}-rtl`]: direction === 'rtl',
                });
                return (<RcPagination {...restProps} prefixCls={prefixCls} selectPrefixCls={selectPrefixCls} {...this.getIconsProps(prefixCls, direction)} className={extendedClassName} selectComponentClass={isSmall ? MiniSelect : Select} locale={locale}/>);
            }}
      </ConfigConsumer>);
        };
    }
    render() {
        return (<LocaleReceiver componentName="Pagination" defaultLocale={enUS}>
        {this.renderPagination}
      </LocaleReceiver>);
    }
}
