var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import RcDrawer from 'rc-drawer';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import classNames from 'classnames';
import omit from 'omit.js';
import { withConfigConsumer } from '../config-provider/context';
import { tuple } from '../_util/type';
const DrawerContext = React.createContext(null);
const PlacementTypes = tuple('top', 'right', 'bottom', 'left');
class Drawer extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            push: false,
        };
        this.push = () => {
            this.setState({
                push: true,
            });
        };
        this.pull = () => {
            this.setState({
                push: false,
            });
        };
        this.onDestroyTransitionEnd = () => {
            const isDestroyOnClose = this.getDestroyOnClose();
            if (!isDestroyOnClose) {
                return;
            }
            if (!this.props.visible) {
                this.destroyClose = true;
                this.forceUpdate();
            }
        };
        this.getDestroyOnClose = () => this.props.destroyOnClose && !this.props.visible;
        // get drawer push width or height
        this.getPushTransform = (placement) => {
            if (placement === 'left' || placement === 'right') {
                return `translateX(${placement === 'left' ? 180 : -180}px)`;
            }
            if (placement === 'top' || placement === 'bottom') {
                return `translateY(${placement === 'top' ? 180 : -180}px)`;
            }
        };
        this.getRcDrawerStyle = () => {
            const { zIndex, placement, style } = this.props;
            const { push } = this.state;
            return Object.assign({ zIndex, transform: push ? this.getPushTransform(placement) : undefined }, style);
        };
        // render drawer body dom
        this.renderBody = () => {
            const { bodyStyle, drawerStyle, prefixCls, visible } = this.props;
            if (this.destroyClose && !visible) {
                return null;
            }
            this.destroyClose = false;
            const containerStyle = {};
            const isDestroyOnClose = this.getDestroyOnClose();
            if (isDestroyOnClose) {
                // Increase the opacity transition, delete children after closing.
                containerStyle.opacity = 0;
                containerStyle.transition = 'opacity .3s';
            }
            return (<div className={`${prefixCls}-wrapper-body`} style={Object.assign(Object.assign({}, containerStyle), drawerStyle)} onTransitionEnd={this.onDestroyTransitionEnd}>
        {this.renderHeader()}
        <div className={`${prefixCls}-body`} style={bodyStyle}>
          {this.props.children}
        </div>
        {this.renderFooter()}
      </div>);
        };
        // render Provider for Multi-level drawer
        this.renderProvider = (value) => {
            const _a = this.props, { prefixCls, placement, className, width, height, mask, direction } = _a, rest = __rest(_a, ["prefixCls", "placement", "className", "width", "height", "mask", "direction"]);
            const haveMask = mask ? '' : 'no-mask';
            this.parentDrawer = value;
            const offsetStyle = {};
            if (placement === 'left' || placement === 'right') {
                offsetStyle.width = width;
            }
            else {
                offsetStyle.height = height;
            }
            const drawerClassName = classNames(className, haveMask, {
                [`${prefixCls}-rtl`]: direction === 'rtl',
            });
            return (<DrawerContext.Provider value={this}>
        <RcDrawer handler={false} {...omit(rest, [
                'zIndex',
                'style',
                'closable',
                'destroyOnClose',
                'drawerStyle',
                'headerStyle',
                'bodyStyle',
                'footerStyle',
                'footer',
                'locale',
                'title',
                'push',
                'visible',
                'getPopupContainer',
                'rootPrefixCls',
                'getPrefixCls',
                'renderEmpty',
                'csp',
                'pageHeader',
                'autoInsertSpaceInButton',
            ])} {...offsetStyle} prefixCls={prefixCls} open={this.props.visible} showMask={mask} placement={placement} style={this.getRcDrawerStyle()} className={drawerClassName}>
          {this.renderBody()}
        </RcDrawer>
      </DrawerContext.Provider>);
        };
    }
    componentDidMount() {
        // fix: delete drawer in child and re-render, no push started.
        // <Drawer>{show && <Drawer />}</Drawer>
        const { visible } = this.props;
        if (visible && this.parentDrawer) {
            this.parentDrawer.push();
        }
    }
    componentDidUpdate(preProps) {
        const { visible } = this.props;
        if (preProps.visible !== visible && this.parentDrawer) {
            if (visible) {
                this.parentDrawer.push();
            }
            else {
                this.parentDrawer.pull();
            }
        }
    }
    componentWillUnmount() {
        // unmount drawer in child, clear push.
        if (this.parentDrawer) {
            this.parentDrawer.pull();
            this.parentDrawer = null;
        }
    }
    renderHeader() {
        const { title, prefixCls, closable, headerStyle } = this.props;
        if (!title && !closable) {
            return null;
        }
        const headerClassName = title ? `${prefixCls}-header` : `${prefixCls}-header-no-title`;
        return (<div className={headerClassName} style={headerStyle}>
        {title && <div className={`${prefixCls}-title`}>{title}</div>}
        {closable && this.renderCloseIcon()}
      </div>);
    }
    renderFooter() {
        const { footer, footerStyle, prefixCls } = this.props;
        if (!footer) {
            return null;
        }
        const footerClassName = `${prefixCls}-footer`;
        return (<div className={footerClassName} style={footerStyle}>
        {footer}
      </div>);
    }
    renderCloseIcon() {
        const { closable, prefixCls, onClose } = this.props;
        return (closable && (
        // eslint-disable-next-line react/button-has-type
        <button onClick={onClose} aria-label="Close" className={`${prefixCls}-close`}>
          <CloseOutlined />
        </button>));
    }
    render() {
        return <DrawerContext.Consumer>{this.renderProvider}</DrawerContext.Consumer>;
    }
}
Drawer.defaultProps = {
    width: 256,
    height: 256,
    closable: true,
    placement: 'right',
    maskClosable: true,
    mask: true,
    level: null,
    keyboard: true,
};
export default withConfigConsumer({
    prefixCls: 'drawer',
})(Drawer);
