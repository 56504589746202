var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import { getConfirmLocale } from './locale';
import { destroyFns } from './Modal';
import ConfirmDialog from './ConfirmDialog';
export default function confirm(config) {
    const div = document.createElement('div');
    document.body.appendChild(div);
    // eslint-disable-next-line no-use-before-define
    let currentConfig = Object.assign(Object.assign({}, config), { close, visible: true });
    function destroy(...args) {
        const unmountResult = ReactDOM.unmountComponentAtNode(div);
        if (unmountResult && div.parentNode) {
            div.parentNode.removeChild(div);
        }
        const triggerCancel = args.some(param => param && param.triggerCancel);
        if (config.onCancel && triggerCancel) {
            config.onCancel(...args);
        }
        for (let i = 0; i < destroyFns.length; i++) {
            const fn = destroyFns[i];
            // eslint-disable-next-line no-use-before-define
            if (fn === close) {
                destroyFns.splice(i, 1);
                break;
            }
        }
    }
    function render(_a) {
        var { okText, cancelText } = _a, props = __rest(_a, ["okText", "cancelText"]);
        const runtimeLocale = getConfirmLocale();
        ReactDOM.render(<ConfirmDialog {...props} okText={okText || (props.okCancel ? runtimeLocale.okText : runtimeLocale.justOkText)} cancelText={cancelText || runtimeLocale.cancelText}/>, div);
    }
    function close(...args) {
        currentConfig = Object.assign(Object.assign({}, currentConfig), { visible: false, afterClose: destroy.bind(this, ...args) });
        render(currentConfig);
    }
    function update(newConfig) {
        currentConfig = Object.assign(Object.assign({}, currentConfig), newConfig);
        render(currentConfig);
    }
    render(currentConfig);
    destroyFns.push(close);
    return {
        destroy: close,
        update,
    };
}
export function withWarn(props) {
    return Object.assign({ type: 'warning', icon: <ExclamationCircleOutlined />, okCancel: false }, props);
}
export function withInfo(props) {
    return Object.assign({ type: 'info', icon: <InfoCircleOutlined />, okCancel: false }, props);
}
export function withSuccess(props) {
    return Object.assign({ type: 'success', icon: <CheckCircleOutlined />, okCancel: false }, props);
}
export function withError(props) {
    return Object.assign({ type: 'error', icon: <CloseCircleOutlined />, okCancel: false }, props);
}
export function withConfirm(props) {
    return Object.assign({ type: 'confirm', okCancel: true }, props);
}
